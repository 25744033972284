import React from "react";
import { graphql } from "gatsby";

import Layout from "../layout/layout";
// import * as styles from "../scss/components/documentation.module.scss";

const Cfi = ({ data }) => {
  //   console.log("mdx :: ", data.markdownRemark);
//   console.log("html :: ", data.markdownRemark.html);
  return (
    <Layout>
      {/* <div style={{ margin: "auto", maxWidth: "70vw" }}> */}
      <main className='container mt-6 mh-2'>
        <h2 className='has-text-centered is-size-3'>Call for Interns</h2>
        <p>
          <a href='https://www.vlab.co.in/'>Virtual Labs</a>, a mission mode
          Ministry of Education project under NMEICT, provides free laboratory
          learning experience to college and university students through virtual
          and remote experiments in Science and Engineering.
        </p>
        <br></br>
        <p>
          All Virtual Labs software is built on open-source technologies and is
          distributed as an open-source application. The Virtual Labs project
          works on the leading edge of technology and follows the latest
          software and standards. The tech stack followed by the Virtual Labs
          is:
        </p>
        <br />
        <ul>
          <li>
            <span className='has-text-weight-bold'>- Deployment: </span>
            The Virtual Labs is deployed on the AWS cloud.<br></br>
          </li>
          <li>
            <span className='has-text-weight-bold'>- Development: </span>The
            Virtual Labs is developed as web applications developed using the
            latest Web Technology standards in HTML5, JavaScript, and CSS3. We
            are also planning to port these as mobile apps.
          </li>
          <li>
            <span className='has-text-weight-bold'>- DevOps: </span>The Virtual
            Labs has automated pipelines for Continuous Integration and Delivery
            implemented using GItHub Actions.
          </li>
        </ul>
        <br />
        <p>
          In a world ravaged by a never seen before pandemic, the Virtual Labs
          impactfully connected with the learning community through a virtual
          mode of learning.
        </p>
        <br></br>
        <p>
          Since January 1st 2020, Virtual Labs has had more than 7 Million
          world-wide users and has accumulated 49 Million visits.
        </p>
        <br></br>
        <p>
          Currently the Virtual Labs is engaged in exciting work across all of
          the technology areas including AWS infrastructure, DevOps and active
          learning material development.
        </p>
        <br></br>
        <p>
          In addition to Simulation Experiments, along with faculty from CCNSB,
          Robotics Research Centre, SPCRC and CVEST, LTRC and we are also
          building remote triggered labs and applying AI to Education and
          Learning.
        </p>
        <br></br>
        <p>
          The Virtual Labs is looking for bright and motivated students to be a
          part of its journey to excellence in virtual learning. The applicants
          are expected to:
        </p>
        <br></br>
        <p>
          <ol>
            <li>
              Have excellent software development skills and knowledge in web
              technologies, i.e., HTML5, Javascript (ES6), CSS3.
            </li>
            <li>
              A good understanding of the latest software development tools
              and trends.
            </li>
            <li>Familiarity with version control.</li>
            <li>
              Passion for learning and motivation to create great software.
            </li>
          </ol>
        </p>
      </main>
      {/* {data && data.markdownRemark ? (
        <div style={{ margin: "auto", maxWidth: "70vw" }}>
          <div className={`${styles.docContainer} columns`}>
            <div
              className={styles.docMain + " box column mt-4"}
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </div>
        </div>
      ) : (
        <p
          style={{
            marginTop: "100px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          No Data Available
        </p>
      )} */}
    </Layout>
  );
};

export const query = graphql`
  query CfiQuery {
    markdownRemark(fileAbsolutePath: { regex: "/.*/cfi.md/" }) {
      frontmatter {
        title
      }
      html
    }
  }
`;

export default Cfi;
